.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#ucsf {
	background: #000000;
	border-bottom: 1px solid rgba(0,0,0,0.2);
}

#ucsf #topbar-wrapper {
	font-family: "Helvetica", sans-serif;
	font-size: 16px;
}

#ucsf #topbar {
	max-width: 940px;
	margin: 0 auto;
}

#ucsf #topbar ul {
	line-height: 1em;
	list-style: none;
	margin: 0;
	padding: 0;
	background-color: #000033;
    text-align: center;
}

#ucsf #topbar ul li {
	display: inline;
  	font-size: .75em;
  	border-left: 1px solid #FFF;
}

#topbar-wrapper #topbar ul li:first-child {
  	margin-right: 0;
	border-left: none;
}

#topbar-wrapper #topbar ul, #topbar-wrapper #topbar ul li, #topbar-wrapper #topbar ul li a {
  line-height: 1em;
  list-style: none;
  margin: 0;
  padding: 0;
}

#topbar-wrapper #topbar ul li:first-child a {
	background: transparent url(http://pharmchem.ucsf.edu/sites/all/themes/pharmacyoa/images/logo-ucsf.png) no-repeat left 3px;
	display: inline-block;
	margin-left: 0;
	min-height: 28px;
	padding-left: 5.5em;
}

#topbar-wrapper #topbar ul li a {
  color: #FFF;
  margin: 0 .5em;
  padding: 9px 0 0 .5em;
  white-space: nowrap;
}